import {graphql, Link, StaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Head from '../components/Head/Head.jsx'
import Page from '../components/Page/Page.jsx'
import {StyledAboutUsImage, StyledAboutUsLinks, StyledAboutUsSection, StyledHeroSection} from '../page-components/index.js'
import {contentfulImagePropTypes, contentfulMarkdownRemarkPropTypes, contentfulMetadataSeoPropTypes} from '../shared-prop-types.js'

const Homepage = (props) => {
  const {content} = props

  return (
    <Page>
      <Head pageContent={content.metadataSEO}/>
      <StyledHeroSection
        backgroundImageURL={`https:${content.heroImage.file.url}`}
        // refer to /components/Page/Page.jsx to see how the `componentID` prop is used
        componentID="Homepage__StyledHeroSection"
      >
        <h1>{content.heroHeading}</h1>
      </StyledHeroSection>
      <StyledAboutUsSection>
        <div dangerouslySetInnerHTML={{__html: content.aboutUsSection.childMarkdownRemark.html}}/>
        <StyledAboutUsLinks>
          <ul>
            {content.aboutUsLinks.map((aboutUsLink) => {
              const linkType = aboutUsLink.target === 'New browser tab' ? 'external' : 'internal'
              let link
              switch (linkType) {
                // TODO: icons for external/internal links
                case 'external':
                  link = <a href={aboutUsLink.href} rel="noreferrer" target="_blank">{aboutUsLink.label}</a>
                  break
                case 'internal':
                  link = <Link to={aboutUsLink.href}>{aboutUsLink.label}</Link>
                  break
                default:
                  throw new Error(`linkType must be one of 'external|internal'; received ${linkType}.`)
              }

              return <li key={aboutUsLink.label}>{link}</li>
            })}
          </ul>
        </StyledAboutUsLinks>
        <StyledAboutUsImage alt={content.aboutUsImage.description} src={`https:${content.aboutUsImage.file.url}`}/>
      </StyledAboutUsSection>
    </Page>
  )
}

Homepage.propTypes = {
  content: PropTypes.shape({
    aboutUsImage: PropTypes.shape(contentfulImagePropTypes).isRequired,
    aboutUsLinks: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        target: PropTypes.oneOf([
          'New browser tab',
          'Same browser tab',
        ]).isRequired,
      }).isRequired,
    ).isRequired,
    aboutUsSection: PropTypes.shape(contentfulMarkdownRemarkPropTypes).isRequired,
    heroHeading: PropTypes.string.isRequired,
    heroImage: PropTypes.shape(contentfulImagePropTypes).isRequired,
    metadataSEO: PropTypes.shape(contentfulMetadataSeoPropTypes).isRequired,
  }).isRequired,
}

const HomepageWithContent = () => (
  <StaticQuery
    // to test queries, refer to the dev server terminal output for the GraphQL URL
    query={graphql`
      query ContentfulPageHomeQuery {
        contentfulPageHome {
          aboutUsImage {
            description
            file {
              url
            }
          }
          aboutUsLinks {
            href
            label
            target
          }
          aboutUsSection {
            childMarkdownRemark {
              html
            }
          }
          heroHeading
          heroImage {
            file {
              url
            }
          }
          metadataSEO {
            canonicalURL
            description
            title
          }
        }
      }
    `}
    render={data => <Homepage content={data.contentfulPageHome}/>}
  />
)

export default HomepageWithContent
