import styled from 'styled-components'
import {colors} from '../config/colors.js'

export const StyledHeroSection = styled.section(props => ({
  display: 'flex',
  alignItems: 'center',
  // refer to /components/Page/Page.jsx to see how the `headerHeightPxState` prop gets set
  height: `calc(100vh - ${props.headerHeightPxState.headerHeightPx})`,
  backgroundImage: `url(${props.backgroundImageURL})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  h1: {
    maxWidth: '50rem',
    padding: '0 2rem',
    margin: '0 auto',
    color: colors['white-#fff'],
    fontSize: '6rem',
    fontWeight: 'bold',
    letterSpacing: '-0.05rem', // i.e. 0.5px
    lineHeight: '1.2',
    textAlign: 'center',
    textShadow: `0.5rem 0.5rem 0.5rem ${colors['black-#000']}`,
    textTransform: 'uppercase',
  },
}))

export const StyledAboutUsSection = styled.section({
  maxWidth: '60rem',
  padding: '4rem 2rem',
  margin: '0 auto',

  h2: {
    marginBottom: '2rem',
    fontSize: '2.4rem',
    textAlign: 'center',
    textTransform: 'uppercase',
  },

  p: {
    marginBottom: '2rem',
  }
})

export const StyledAboutUsLinks = styled.div({
  marginBottom: '2rem',

  ul: {
    listStyleType: 'none',
  },

  li: {
    marginBottom: '1rem',
    '&:last-of-type': {
      marginBottom: 'unset',
    },
  },

  a: {
    display: 'block',
    maxWidth: '45rem',
    margin: '0 auto',
    backgroundColor: colors['black-#000'],
    color: colors['white-#fff'],
    fontWeight: 'bold',
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

export const StyledAboutUsImage = styled.img({
  display: 'block',
  maxWidth: '100%',
})
